<template>
    <b-modal id="modal-import" cancel-variant="secondary" ok-title="Add" cancel-title="Batal" centered size="lg">
        <b-row>
            <b-col md="12" sm="12">
                <h3>{{ $t('Download Format Import XLSX') }}</h3>
                <xlsx-workbook>
                    <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name"
                        :sheet-name="sheet.name" />
                    <xlsx-download>
                        <b-button v-if="allowCreate()" variant="success">
                            <feather-icon icon="DownloadIcon" class="mr-50" />
                            {{ $t('Download') }}
                        </b-button>
                    </xlsx-download>
                </xlsx-workbook>
            </b-col>
            <b-col md="12" sm="12">
                <section>
                    <h3 class="mt-1">Import XLSX</h3>
                    <b-form-file type="file" @change="onChange" class="mb-1" />
                    <xlsx-read :file="file">
                        <xlsx-sheets>
                            <template #default="{ sheets }">
                                <b-form-select v-model="selectedSheet" class="mb-1">
                                    <b-form-select-option :key="null" :value="null">
                                        {{ $t('Choose Sheet') }}
                                    </b-form-select-option>
                                    <b-form-select-option v-for="sheet in sheets" :key="sheet" :value="sheet">
                                        {{ sheet }}
                                    </b-form-select-option>
                                </b-form-select>
                            </template>
                        </xlsx-sheets>
                        <!-- <xlsx-table :sheet="selectedSheet" /> -->
                        <xlsx-json :sheet="selectedSheet">
                            <template #default="{ collection }">
                                <div>
                                    <!-- {{ collection }} -->
                                </div>
                                <b-button v-if="allowCreate()" @click.prevent="submitimport(collection)"
                                    variant="primary">
                                    <feather-icon icon="UploadIcon" class="mr-50" />
                                    {{ $t('Import') }}
                                </b-button>
                            </template>
                        </xlsx-json>
                    </xlsx-read>
                </section>

            </b-col>
        </b-row>

        <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
                <!-- <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                    Simpan
                </b-button> -->
            </section>
        </template>
    </b-modal>
</template>
<script>
import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson, XlsxWorkbook, XlsxSheet, XlsxDownload } from "../docs/dist/vue-xlsx.es.js"
import { BModal, BButton, BForm, BRow, BCol, BFormGroup, BFormSelect, BFormSelectOption, BFormInput, BFormTextarea, BFormFile, } from 'bootstrap-vue'
export default {
    props: ['item'],
    components: {
        BModal, BButton, BForm, BRow, BCol, BFormGroup, BFormSelect, BFormSelectOption, BFormInput, BFormTextarea, BFormFile,
        XlsxRead,
        XlsxTable,
        XlsxSheets,
        XlsxJson,
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
    },
    watch: {
        item(val) {
            if (val.nama) {
                this.form.nama = val.nama
                this.form.instansi = val.instansi
                this.form.email = val.email
                this.form.nohp = val.nohp
                this.form.alamat = val.alamat
                this.form.memo = val.memo
            }
        }
    },
    data: () => ({
        file: null,
        selectedSheet: null,
        sheetName: null,
        sheets: [{ name: "Sheet", data: [{ nama: 'Contoh: supplier nama', nohp_pic: '841982419', instansi: 'PT. heykama', nohp: '08124812894', email: 'owner@heykama.com', top: 'Cash', alamat: 'Bandung, buahbatu', memo: 'Note' }] }],
        collection: [{ a: 1, b: 2 }],

    }),
    computed: {
        isValidForm() {
            if (!this.form.nama || !this.form.instansi || !this.form.nohp || !this.form.nohp_pic) {
                return false
            }

            return true
        }
    },
    methods: {

        onChange(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        addSheet() {
            this.sheets.push({ name: this.sheetName, data: [...this.collection] });
            this.sheetName = null;
        },
        resetForm() {
        },
        submitimport(cek) {

            cek.map((hem) => {
                hem.jenis = 1;
            });
            let payload = cek
            console.log('cek', cek)
            // if (this.id) {
            //   payload.id = this.id
            // }
            if (this.isOwner || this.isCEO) {
                payload.jenis = 1
            }
            else {
                payload.jenis = 2
            }
            try {
                this.loading = true
                this.$store.dispatch('supplier/save', payload)
                this.displaySuccess({
                    message: 'Berhasil'
                })
                this.$emit('getData', payload, (status) => {
                    if (status) this.resetForm
                })
                this.$bvModal.hide('modal-import')

                this.loading = false
            }
            catch (e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    }
}
</script>