<template>
    <b-overlay :show="loading">
        <b-row>
            <b-col class="my-1">
                <b-button-group>
                    <b-button v-if="allowCreate()" @click.prevent="add" variant="primary">
                        <!-- <feather-icon icon="PlusIcon" class="mr-50" /> -->
                        {{ $t('Add') }}
                    </b-button>
                    <!-- <xlsx-workbook>
                        <xlsx-sheet :collection="pembelians" sheet-name="Sheet1" />
                        <xlsx-download filename="Export Data Purchase"> -->
                    <!-- <b-button v-if="allowCreate()" variant="success">
                        <feather-icon icon="DownloadIcon" class="mr-50" />
                        {{ $t('Export') }}
                    </b-button> -->
                    <!-- </xlsx-download>
                    </xlsx-workbook> -->
                    <b-button v-if="allowCreate()" @click.prevent="addimport" variant="warning">
                        <!-- <feather-icon icon="UploadIcon" class="mr-50" /> -->
                        {{ $t('Import') }}
                    </b-button>
                    <modal-import @getData="getData"></modal-import>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col md="2" sm="6" class="my-1">
                            <b-form-group class="mb-0" :label="$t('Per page')" label-size="sm"
                                label-for="perPageSelect">
                                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="6" class="my-1">
                            <b-form-group :label="$t('Sort')" label-size="sm" label-for="sortBySelect" class="mb-0">
                                <b-input-group size="sm">
                                    <!-- <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" @change="getData()" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
</b-form-select> -->
                                    <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" @change="getData()"
                                        class="w-100">
                                        <option :value="false">Asc</option>
                                        <option :value="true">Desc</option>
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="8" sm="12" class="my-1">
                            <b-form-group :label="$t('Filter')" label-for="filterInput" class="mb-0" size="sm">
                                <b-input-group>
                                    <b-form-input v-model="filter" placeholder="Tekan enter untuk cari" size="sm"
                                        @keydown.enter.prevent="getData()"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="outline-primary" @click="getData()" size="sm">
                                            {{ $t('Search') }}
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <!-- / -->
                    </b-row>
                    <b-row>
                        <b-col md="4" sm="4" class="my-1">
                            <b-form-group class="mb-0" :label="$t('Supplier')" label-size="sm"
                                label-for="supplierSelect">
                                <b-form-select id="supplierSelect" v-model="supplier" size="sm"
                                    :options="supplierOptions" @change="getData()">

                                </b-form-select>
                            </b-form-group>
                        </b-col>

                    </b-row>
                    <!-- Body -->
                    <b-overlay :show="loading">
                        <b-row>
                            <template v-if="pembelians.length > 0">
                                <b-col cols="12">
                                    <b-table :filter="filter" :per-page="perPage" :current-page="currentPage" responsive
                                        small :fields="fields" :items="pembelians">
                                        <template #cell(order)="{ index }">
                                            {{ 1 + index }}
                                        </template>
                                        <template #cell(jenis)="{ item }">
                                            {{ item.jenis == 1 ? 'Rokok' : 'Non-Rokok' }}
                                        </template>
                                        <template #cell(tanggal)="{ item }">
                                            {{ humanDate(item.tanggal) }}
                                        </template>
                                        <template #cell(nama)="{ item }">
                                            <template v-if="item.instansi">
                                                {{ item.instansi }}
                                            </template>
                                            <template v-else>
                                                <span class='text-danger'>Data tidak ada</span>
                                            </template><br>
                                            <template v-if="item.nama">
                                                <small>PIC : {{ item.nama }}</small>
                                            </template>
                                            <template v-else>
                                                <small>PIC : <span class='text-danger'>Data tidak ada</span></small>
                                            </template>
                                        </template>
                                        <template #cell(total)="{ item }">
                                            <span v-if="item.informasi">
                                                Rp {{ formatRupiah(item.informasi.total_pembelian) }}
                                            </span>
                                            <i class="text-danger" v-else>
                                                Tidak ada informasi
                                            </i>
                                        </template>
                                        <!-- <template #cell(status)="{ item }">
                                            <b-badge v-if="item.informasi && item.informasi.hutang == 0"
                                                variant="light-success">
                                                Lunas
                                            </b-badge>
                                            <b-badge v-else variant="light-danger">Belum Lunas</b-badge>
                                        </template> -->
                                        <template #cell(status)="{ item }">
                                            <template v-if="item.penerimaan_id != null">
                                                <b-badge variant="success">
                                                    Diterima
                                                </b-badge>
                                                <br>
                                                <template v-if="item.selesai == 0">
                                                    <b-badge variant="warning">
                                                        Proses Pengecekan
                                                    </b-badge>
                                                </template>
                                                <template v-if="item.selesai == 1">
                                                    <b-badge variant="success">
                                                        Selesai Pengecekan
                                                    </b-badge>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <b-badge variant="danger">Belum Diterima</b-badge>
                                            </template>
                                        </template>
                                        <template #cell(actions)="{ item }">
                                            <div class="flex align-items-center justify-center">
                                                <b-button size="sm" @click="detail(item)" class="mr-1"
                                                    variant="success">
                                                    <feather-icon icon="EyeIcon" />
                                                </b-button>
                                                <b-button v-if="allowUpdate() && item.penerimaan_id == null" size="sm"
                                                    @click="edit(item)" class="mr-1" variant="info">
                                                    <feather-icon icon="EditIcon" />
                                                </b-button>
                                                <b-button v-if="allowUpdate()" size="sm" @click="excelexport(item)"
                                                    class="mr-1" variant="success">
                                                    <feather-icon icon="FileIcon" />
                                                    {{ $t('Export Excel') }}
                                                </b-button>
                                                <!-- <b-button
                                                    v-if="allowUpdate(modulePembelian) && item.informasi.total_bayar < 1 && !hasPermissionPay"
                                                    size="sm" @click="edit(item)" class="mr-1" variant="info">
                                                    <feather-icon icon="EditIcon" />
                                                </b-button>
                                                <b-button v-if="allowDelete(modulePembelian) && !hasPermissionPay"
                                                    size="sm" @click="remove(item)" class="mr-1" variant="danger">
                                                    <feather-icon icon="TrashIcon" />
                                                </b-button> -->
                                            </div>
                                        </template>
                                    </b-table>
                                </b-col>
                                <b-col cols="12">
                                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                        align="center" size="sm" class="my-0" />
                                </b-col>
                            </template>
                            <template v-else>
                                <b-col cols="12">
                                    <div class="alert alert-danger p-2"><strong>{{ $t('Data tidak ada') }}</strong>
                                    </div>
                                </b-col>
                            </template>
                        </b-row>
                    </b-overlay>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson, XlsxWorkbook, XlsxSheet, XlsxDownload } from "../docs/dist/vue-xlsx.es.js"
import ModalImport from './Import.vue'
import { BOverlay, BDropdown, BDropdownItem, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BButtonGroup, BFormInput, BFormSelect } from 'bootstrap-vue'
import service from '@/services'
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import vSelect from 'vue-select'
import pembelian from "../../../router/routes/pembelian.js"
export default {
    components: {
        ModalImport,
        XlsxRead,
        XlsxTable,
        XlsxSheets,
        XlsxJson,
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
        vSelect,
        FormFilterExport,
        BOverlay, BDropdown, BDropdownItem,
        BRow, BCol, BBadge, BFormGroup, BCard, BPagination, BTable, BInputGroup, BInputGroupAppend, BButton, BButtonGroup, BFormInput, BFormSelect
    },
    data: () => ({
        supplier: null,
        supplierOptions: [],
        selectedJenis: 2,
        modulePembelian: 'Pembelian',
        loading: false,
        pageOptions: [10, 20, 50, 100],
        sortBy: "desc",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        perPage: 10,
        totalRows: 1,
        currentPage: 1,
        fields: [
            { key: 'order', label: 'No' },
            { key: 'no', label: 'Invoice' },
            { key: 'tanggal', label: 'Tanggal', sortable: true },
            { key: 'jatuh_tempo', label: 'Tanggal Perkiraan', sortable: true },
            { key: 'nama', label: 'Supplier' },
            //   {key: 'total', label: 'Total Pembelian'},
            { key: 'status', label: 'Status' },
            { key: 'actions', label: '#' }
        ],
        pembelians: [],
        jenis: null,
        menu: null,
        myDataOnly: false,
        hasPermissionPay: false
    }),
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({ text: f.label, value: f.key }));
        },
    },
    methods: {
        async excelexport(item) {
            let payload = {
                pembelian_id: item.id
            }
            try {
                this.loading = true
                let response = await this.$store.dispatch('pembelian/export', payload)
                this.loading = false
                this.displaySuccess({
                    message: 'Export Excel Berhasil'
                })
                window.open(response)
                // this.getData()
            }
            catch (e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        addimport() {
            // this.resetForm()
            this.$bvModal.show('modal-import')
        },
        async getDataSupplier() {
            this.$store
                .dispatch("supplier/getData")
                .then((response) => {
                    let items = JSON.parse(JSON.stringify(this.$store.state.supplier.datas));
                    // let items_total = this.$store.state.supplier.totals;
                    this.supplierOptions = items
                    this.supplierOptions.map((hem) => {
                        hem.text = hem.nama;
                        hem.value = hem.id;
                    });
                    // palets.forEach((palet) => {
                    this.supplierOptions.push({
                        value: null,
                        text: this.$t('All'),
                    });
                    // });
                    // this.totalRows = items_total
                }).catch((e) => {
                    this.displayError(e);
                    return false;
                });
        },
        async doExport({ tgl_awal, tgl_akhir }, type_export = 'pembayaran') {
            if (!tgl_awal || !tgl_akhir) {
                this.displayError({
                    message: 'Harap isi tanggal awal dan akhir!'
                })
                return false
            }

            if (this.selectedJenis == null) {
                this.displayError({
                    message: 'Harap pilih jenis pembayaran'
                })
                return false
            }

            try {
                const url = type_export == 'pembayaran' ? `/excel-rekap-pembayaran-beli` : `excel-rekap-pembelian`
                const config = {
                    method: 'get',
                    url,
                    params: {
                        jenis: this.selectedJenis,
                        tgl_awal,
                        tgl_akhir
                    },
                    headers: {
                        Authorization: `bearer ${localStorage.token}`
                    }
                }

                this.loading = true
                const response = await service.sendRequest(config)
                this.loading = false
                await window.open(response.data)
                setTimeout(async () => {
                    // delete selected file
                    const arrFile = response.data.split('/')
                    const filename = arrFile[arrFile.length - 1]

                    await this.clearExportedFile(filename)
                }, 1000)
            }
            catch (e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async checkPermissionPay() {
            const menu = await this.currentMenu(this.$route.path)
            const params = {
                level_id: this.user.level.id
            }
            if (menu) {
                params.menu_id = menu.id
            }
            const listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionPay = listStatus.some(status => status.data_status == 2)
            this.hasPermissionPay = hasPermissionPay
        },
        edit(item) {
            this.$router.push(`/pembelian/edit/${item.id}`)
        },
        remove(item) {
            this.$swal({
                title: 'Anda yakin?',
                text: `Data pembelian ini akan dihapus`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
                .then(async res => {
                    if (res.value) {
                        const payload = {
                            fungsi: 1,
                            id: item.id
                        }

                        try {
                            this.loading = true
                            await this.$store.dispatch('pembelian/save', [payload])
                            this.loading = false
                            this.displaySuccess({
                                message: 'Data pembelian berhasil dihapus'
                            })
                            this.getData()
                        }
                        catch (e) {
                            this.loading = false
                            this.displayError(e)
                            return false
                        }
                    }
                })
        },
        add() {
            this.$router.push(`/pembelian/add`)
        },
        async getData() {
            this.loading = true
            const perPage = parseInt(this.perPage);
            const currentPage = (parseInt(this.currentPage) - 1) * perPage;
            const params = {
                search: this.filter != null ? this.filter : null,
                order: this.sortDesc ? this.sortDesc : 'desc',
                start: currentPage,
                length: this.perPage,
                // filter_by: "nama_lengkap",
                supplier_id: this.supplier,
                id_supplier: this.supplier,
            };
            this.$store
                .dispatch("pembelian/getData", params)
                .then((response) => {
                    let items = JSON.parse(JSON.stringify(this.$store.state.pembelian.datas));
                    let items_total = this.$store.state.pembelian.totals;
                    this.pembelians = items
                    this.totalRows = items_total
                    this.loading = false
                }).catch((e) => {
                    this.displayError(e);
                    this.loading = false
                    return false;
                });
        },
        detail(item) {
            this.$router.push(`/pembelian/detail/${item.id}`)
        },
        async getJenisData() {
            const params = {
                level_id: this.user.level.id
            }
            if (this.menu) params.menu_id = this.menu.id

            const status = await this.$store.dispatch('statusrole/getData', params)
            const findJenis1 = status.find(st => st.data_status == 11)
            const findJenis2 = status.find(st => st.data_status == 22)

            if (findJenis1) {
                this.jenis
            }
            else if (findJenis2) {
                this.jenis = 2
            }
            else if ((findJenis2 && findJenis1) || this.isOwner || this.isCEO) {
                this.jenis = null
            }
            else {
                this.jenis = null
            }
        },
        async getCurrentMenu() {
            this.menu = await this.currentMenu()
        },
        setField() {
            if (this.isHeadwh) {
                this.fields = [
                    { key: 'order', label: 'No' },
                    { key: 'no', label: 'No. Pembelian' },
                    { key: 'tanggal', label: 'Tanggal' },
                    { key: 'total', label: 'Total Pembelian' },
                    { key: 'status', label: 'Status' },
                    { key: 'actions', label: '#' }
                ]
            }
            else if (this.isSPV || this.isCEO) {
                this.fields = [
                    { key: 'order', label: 'No' },
                    { key: 'no', label: 'No. Pembelian' },
                    { key: 'jenis', label: 'Jenis Pembelian Barang' },
                    { key: 'tanggal', label: 'Tanggal' },
                    { key: 'total', label: 'Total Pembelian' },
                    { key: 'status', label: 'Status' },
                    { key: 'actions', label: '#' }
                ]
            }
        }
    },
    watch: {
        perPage(value) {
            if (value) {
                this.getData();
            }
        },
        // filter(keyword) {
        //   if (keyword.length > 2 || keyword.length == 0) {
        //     this.getData();
        //   }
        // },
        currentPage(val) {
            if (val) {
                this.getData();
            }
        },
    },
    async mounted() {
        // this.checkPermissionPay()
        // await this.getCurrentMenu()
        // await this.getJenisData()
        // await this.setField()
        await this.getData()
        this.getDataSupplier()
    }
}
</script>